import axios from 'axios'

const api = axios.create({
    baseURL: 'https://cchen.nucat.gatech.edu/api',
    // baseURL: 'http://localhost:3000/api',
})

export const insertEvent = payload => api.post(`/event`, payload)
export const getAllEvents = () => api.get(`/events`)
export const updateEventById = (id, payload) => api.put(`/event/${id}`, payload)
export const deleteEventById = id => api.delete(`/event/${id}`)
export const getEventById = id => api.get(`/event/${id}`)

const apis = {
    insertEvent,
    getAllEvents,
    // updateEventById,    // TODO
    // deleteEventById,    // TODO
    getEventById,
}

export default apis